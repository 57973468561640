import { ReactNode, useMemo } from 'react';

import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { SearchAlgoliaImportedHit } from 'modules/search/algolia/types/SearchAlgoliaImportedHit';
import { SearchHitImage } from 'modules/search/components/Hit/Image/SearchHitImage';
import { SearchHitOwnerOrgBanner } from 'modules/search/components/Hit/OrgOwnerBanner/SearchHitOwnerOrgBanner';
import { SearchHitSaveButtonContainer } from 'modules/search/components/Hit/SaveButton/SearchHitSaveButtonContainer';
import {
  SearchHitCard,
  SearchHitHolder,
  SearchHitHoverEffect,
  SearchHitImageHolder,
  SearchHitLink,
  SearchHitSaveButtonHolder,
} from 'modules/search/components/Hit/SearchHit.styled';
import { getImportedListingUrl } from 'modules/search/helpers/getImportedListingUrl';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { getCityStateString } from 'utils/address/getCityStateString';
import { VOLOP_IMPORTED_TYPE_MAP } from 'utils/constants/general/volopImportedTypeMap';
import { getCountryName, getUsStateName } from 'utils/internationalization';

import { SearchImportedHitInfo } from './SearchImportedHitInfo';

type Props = {
  hit: SearchAlgoliaImportedHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hidePublished?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  variant: 'search' | 'sidebar';
};

export function SearchImportedHit({
  hit,
  trackClick,
  showLabel,
  hidePublished,
  hideCta,
  hideOwnerBanner,
  highlighted,
  application,
  variant,
}: Props) {
  const { user } = useUserEnvironment();
  const {
    name,
    city,
    state,
    country,
    url,
    startDate,
    startTime,
    endDate,
    endTime,
    endsLocal,
    startsLocal,
    timezone,
    objectID,
    orgID,
    orgType,
    source,
    actionType,
    locationType,
    remoteZone,
    amountRequested,
    amountRaised,
    goal,
    signatureCount,
    logo,
    imageUrl,
    imageHandle,
    published,
    isPostedAnonymously,
    siteName,
  } = hit;

  const imageHandleImage = imageHandle ? { handle: imageHandle } : undefined;

  const amountCurrent = amountRaised || signatureCount;
  const amountTotal = amountRequested || goal;

  const location = useMemo(() => {
    if (remoteZone !== undefined && remoteZone === 'WORLD') {
      return getText('Anywhere');
    }

    if (remoteZone !== undefined && remoteZone === 'COUNTRY') {
      return getCountryName(country);
    }

    if (remoteZone !== undefined && remoteZone === 'STATE') {
      return getUsStateName(state);
    }

    return getCityStateString({
      city,
      stateCode: state,
      country,
    });
  }, [city, country, remoteZone, state]);

  const listingStats = useMemo(() => {
    const stats: ReactNode[] = [];

    if (
      published &&
      !hidePublished &&
      source &&
      ['DO_SOMETHING', 'GRAPEVINE'].includes(source)
    ) {
      stats.push(
        /* TRANSLATORS Ongoing */
        getText('Ongoing'),
      );
    }

    if (source) {
      stats.push(VOLOP_IMPORTED_TYPE_MAP[source]);
    }

    return stats;
  }, [hidePublished, published, source]);

  const showSaveButton = !hideCta;

  let { orgName } = hit;
  if (source === 'VOLUNTEERGOV') {
    orgName = orgName ? `${siteName} (${orgName})` : `${siteName}`;
  }

  const ownerOrg = user ? user.orgs.find((o) => o.id === orgID) : null;
  const startDateTime = startDate && startTime ? startsLocal : null;
  const endDateTime = endDate && endTime ? endsLocal : null;

  return (
    <SearchHitHolder data-qa-id="search-result" data-hit-id={objectID}>
      {!hideOwnerBanner && ownerOrg && (
        <SearchHitOwnerOrgBanner org={ownerOrg} hit={hit} />
      )}

      <SearchHitCard>
        <SearchHitLink
          $variant={variant}
          $roundedTop={hideOwnerBanner || !ownerOrg}
          $highlighted={Boolean(highlighted)}
          data={{
            type: 'link',
            href: getImportedListingUrl(url, source),
          }}
          onClick={() => trackClick?.()}
        >
          <SearchHitHoverEffect />

          <SearchHitImageHolder $hiddenOnMobile $variant={variant}>
            <SearchHitImage
              isImported
              imageUrl={imageUrl}
              image={imageHandleImage}
              source={source}
              name={name}
              logo={logo || undefined}
            />
          </SearchHitImageHolder>

          <SearchImportedHitInfo
            actionType={actionType}
            amountCurrent={amountCurrent}
            amountTotal={amountTotal}
            application={application}
            endDateTime={endDateTime}
            isPostedAnonymously={isPostedAnonymously}
            listingStats={listingStats}
            location={location}
            locationType={locationType}
            name={name}
            orgName={orgName}
            orgType={orgType}
            showLabel={showLabel}
            startDateTime={startDateTime}
            timezone={timezone}
            variant={variant}
          />
        </SearchHitLink>

        {showSaveButton && (
          <SearchHitSaveButtonHolder $variant={variant}>
            <SearchHitSaveButtonContainer hit={hit} />
          </SearchHitSaveButtonHolder>
        )}
      </SearchHitCard>
    </SearchHitHolder>
  );
}
