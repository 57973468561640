import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { SearchEventHit } from 'modules/search/components/EventHit/SearchEventHit';
import { SearchImportedHit } from 'modules/search/components/ImportedHit/SearchImportedHit';
import { SearchInternshipHit } from 'modules/search/components/InternshipHit/SearchInternshipHit';
import { SearchJobHit } from 'modules/search/components/JobHit/SearchJobHit';
import { SearchOrgHit } from 'modules/search/components/OrgHit/SearchOrgHit';
import { SearchVolopHit } from 'modules/search/components/VolopHit/SearchVolopHit';
import { SearchFacet } from 'modules/search/types/SearchFacet';

type Props = {
  hit: SearchAlgoliaHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hidePublished?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  variant: 'search' | 'sidebar';
  searchContextFacets?: SearchFacet[];
};

export function SearchHit(props: Props) {
  const { hit, ...otherProps } = props;

  switch (hit.type) {
    case 'JOB':
      return (
        <SearchJobHit
          hit={hit}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
    case 'INTERNSHIP':
      return (
        <SearchInternshipHit
          hit={hit}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
    case 'VOLOP':
      return (
        <SearchVolopHit
          hit={hit}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
    case 'EVENT':
      return (
        <SearchEventHit
          hit={hit}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
    case 'IMPORTED':
      return (
        <SearchImportedHit
          hit={hit}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
    case 'ORG':
      return (
        <SearchOrgHit
          hit={hit} // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      );
  }
}
