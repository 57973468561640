import { fromUnixTime } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { sprintf } from 'sprintf-js';

import { ActivelyHiringBadge } from 'components/ActivelyHiringBadge/ActivelyHiringBadge';
import { ListingStatusLabelTag } from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { SearchAlgoliaOrgHit } from 'modules/search/algolia/types/SearchAlgoliaOrgHit';
import { SearchHitImage } from 'modules/search/components/Hit/Image/SearchHitImage';
import { SearchHitOwnerOrgBanner } from 'modules/search/components/Hit/OrgOwnerBanner/SearchHitOwnerOrgBanner';
import { SearchHitSaveButtonContainer } from 'modules/search/components/Hit/SaveButton/SearchHitSaveButtonContainer';
import {
  SearchHitCard,
  SearchHitHolder,
  SearchHitHoverEffect,
  SearchHitImageHolder,
  SearchHitLink,
  SearchHitSaveButtonHolder,
} from 'modules/search/components/Hit/SearchHit.styled';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { colors } from 'theme/theme';
import { getCityStateString } from 'utils/address/getCityStateString';
import { AREAS_OF_FOCUS } from 'utils/constants/general/areasOfFocus';
import { distanceInWordsToNow } from 'utils/date';
import { shuffleArray } from 'utils/functional';

import { SearchOrgHitInfo } from './SearchOrgHitInfo';

type Props = {
  hit: SearchAlgoliaOrgHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hidePublished?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  variant: 'search' | 'sidebar';
  searchContextFacets?: SearchFacet[];
};

export function SearchOrgHit({
  hit,
  trackClick,
  showLabel,
  hidePublished,
  hideCta,
  hideOwnerBanner,
  highlighted,
  variant,
  searchContextFacets: searchContextFacetsProp,
}: Props) {
  const locationStateSearchContext =
    useLocation<SearchContextHeaderLocationState>().state?.showSearchContext;

  const searchContextFacets =
    searchContextFacetsProp || locationStateSearchContext?.searchFacets;

  const { user } = useUserEnvironment();
  const {
    objectID,
    type,
    name,
    orgType,
    published,
    city,
    state,
    country,
    url,
    logo,
    hiring,
    seekingVolunteers,
    hasUpcomingEvents,
    areasOfFocus,
  } = hit;

  const location = useMemo(
    () =>
      getCityStateString({
        city,
        stateCode: state,
        country,
      }),
    [city, country, state],
  );

  const pillItems: string[] = [];
  if (type === 'ORG' && areasOfFocus) {
    let trimmedAreasOfFocus = areasOfFocus;
    if (areasOfFocus.length > 3) {
      trimmedAreasOfFocus = shuffleArray(areasOfFocus).slice(0, 3);
    }

    trimmedAreasOfFocus.forEach((areaOfFocus) =>
      pillItems.push(AREAS_OF_FOCUS[areaOfFocus]),
    );
  }

  const orgStat = useMemo(() => {
    if (hiring) {
      return <ActivelyHiringBadge />;
    }
    if (hasUpcomingEvents) {
      return (
        <ListingStatusLabelTag
          color={colors.mediumContentGrey}
          bg={colors.lemon}
        >
          {getText('Upcoming Events')}
        </ListingStatusLabelTag>
      );
    }
    if (seekingVolunteers) {
      return (
        <ListingStatusLabelTag
          color={colors.mediumContentGrey}
          bg={colors.lemon}
        >
          {getText('Seeking Volunteers')}
        </ListingStatusLabelTag>
      );
    }
    return null;
  }, [hasUpcomingEvents, hiring, seekingVolunteers]);

  const listingStats = useMemo(() => {
    const stats: ReactNode[] = [];

    if (published && !hidePublished) {
      stats.push(
        sprintf(getText('Posted %(postedAt)s'), {
          postedAt: distanceInWordsToNow(fromUnixTime(published)),
        }),
      );
    }

    return stats;
  }, [hidePublished, published]);

  const showSaveButton = !hideCta;

  const ownerOrg = user ? user.orgs.find((o) => o.id === objectID) : null;

  return (
    <SearchHitHolder data-qa-id="search-result" data-hit-id={objectID}>
      {!hideOwnerBanner && ownerOrg && (
        <SearchHitOwnerOrgBanner org={ownerOrg} hit={hit} />
      )}

      <SearchHitCard>
        <SearchHitLink
          $variant={variant}
          $roundedTop={hideOwnerBanner || !ownerOrg}
          $highlighted={Boolean(highlighted)}
          data={{
            type: 'link-with-state',
            to: url[CURRENT_LOCALE],
            state: searchContextFacets
              ? ({
                  showSearchContext: { searchFacets: searchContextFacets },
                } satisfies SearchContextHeaderLocationState)
              : {},
          }}
          onClick={() => trackClick?.()}
        >
          <SearchHitHoverEffect />

          <SearchHitImageHolder $hiddenOnMobile={!logo} $variant={variant}>
            <SearchHitImage
              isImported={false}
              imageUrl={undefined}
              image={undefined}
              source={undefined}
              name={name}
              logo={logo || undefined}
            />
          </SearchHitImageHolder>

          <SearchOrgHitInfo
            hiring={hiring}
            location={location}
            listingStats={listingStats}
            orgStat={orgStat}
            name={name}
            orgType={orgType}
            showLabel={showLabel}
            variant={variant}
            pillItems={pillItems}
          />
        </SearchHitLink>

        {showSaveButton && (
          <SearchHitSaveButtonHolder $variant={variant}>
            <SearchHitSaveButtonContainer hit={hit} />
          </SearchHitSaveButtonHolder>
        )}
      </SearchHitCard>
    </SearchHitHolder>
  );
}
