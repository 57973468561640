import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { Initials } from 'components/Initials';

type Props = {
  width: number;
  height: number;
  name: string;
  logo: ApiFilestackImageData | undefined;
};

export function SearchHitImageLogo({ width, height, name, logo }: Props) {
  if (logo) {
    return (
      <FilestackImage
        data-qa-id="logo-uploaded-image"
        width={width}
        height={height}
        expandSize
        objectFit="contain"
        objectPosition="center center"
        data={logo}
        alt={name}
        noCrop
      />
    );
  }

  return <Initials name={name} size={width} />;
}
