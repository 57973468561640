import { useMemo } from 'react';
import styled from 'styled-components';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { ApiJobType } from 'modules/listing/api/job/types/ApiJobType';
import {
  LocationLabelCombined,
  TagCombined,
  TagNew,
} from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { colors } from 'theme/theme';
import { shorterDate, shorterTime } from 'utils/date';

import {
  SearchHitInfoBoxListingLabelsHolder,
  SearchHitInfoBoxListingLabelsPill,
  SearchHitInfoBoxListingLabelsPillContainer,
} from './SearchHitInfoBoxListingLabels.styled';

type Props = {
  listingType: 'ORG' | 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT' | 'IMPORTED';
  locationType?: ApiListingLocationType;
  location?: string;
  jobType?: ApiJobType[];
  salaryFormatted?: string;
  timezone?: string | null;
  startDateTime?: ApiISODateTimeString | null;
  endDateTime?: ApiISODateTimeString | null;
  variant: 'search' | 'sidebar';
  pillItems?: string[];
};

const BottomTagContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export function SearchHitInfoBoxListingLabels({
  listingType,
  location,
  jobType,
  locationType,
  salaryFormatted,
  timezone,
  startDateTime,
  endDateTime,
  variant,
  pillItems,
}: Props) {
  const jobTypeLabel = useMemo(() => {
    // Note: order is important here.
    if (jobType?.includes('CONTRACT')) return getText('Contract/Freelance');
    if (jobType?.includes('FULL_TIME')) return getText('Full Time');
    if (jobType?.includes('PART_TIME')) return getText('Part Time');
  }, [jobType]);

  return (
    <SearchHitInfoBoxListingLabelsHolder $variant={variant}>
      {location && locationType && (
        <LocationLabelCombined location={location} type={locationType} />
      )}

      <BottomTagContainer>
        {listingType === 'JOB' && (
          <TagNew
            $thickLeftBorder
            $color={colors.mediumContentGrey}
            $bg={colors.white}
            $borderColor={colors.elementGrey}
          >
            {jobTypeLabel}
          </TagNew>
        )}

        {salaryFormatted && (
          <TagNew
            $thickLeftBorder
            $color={colors.mediumContentGrey}
            $bg={colors.white}
            $borderColor={colors.elementGrey}
          >
            {salaryFormatted}
          </TagNew>
        )}

        {(startDateTime || endDateTime) && (
          <TagCombined
            color={colors.white}
            bg={colors.elementGrey}
            iconName="calendar"
            annex={`${
              startDateTime
                ? `${shorterDate(startDateTime)} ${shorterTime(
                    startDateTime,
                    timezone,
                    !endDateTime,
                  )}`
                : ''
            }${startDateTime && endDateTime ? ' – ' : ''}${
              endDateTime
                ? `${shorterDate(endDateTime)} ${shorterTime(
                    endDateTime,
                    timezone,
                    true,
                  )}`
                : ''
            }`}
          />
        )}
        {pillItems && (
          <SearchHitInfoBoxListingLabelsPillContainer>
            {pillItems.map((pillItem) => (
              <SearchHitInfoBoxListingLabelsPill key={pillItem}>
                {pillItem}
              </SearchHitInfoBoxListingLabelsPill>
            ))}
          </SearchHitInfoBoxListingLabelsPillContainer>
        )}
      </BottomTagContainer>
    </SearchHitInfoBoxListingLabelsHolder>
  );
}
