import styled from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const SearchHitOwnerOrgBannerHolder = styled.div`
  align-items: center;
  background: ${colors.brandBlue};
  border-radius: 4px 4px 0 0;
  color: white;
  display: block;
  justify-content: space-between;
  padding: 12px 20px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    display: flex;
    padding: 16px 12px 16px 20px;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    padding: 16px 12px 16px 30px;
  }
`;
