import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { ListingStatusLabelTag } from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { Color, colors } from 'theme/theme';

type Props = {
  badgeColor?: Color;
  textColor?: Color;
};

export function ActivelyHiringBadge({
  badgeColor = 'lemon',
  textColor = 'mediumContentGrey',
}: Props) {
  return (
    <ListingStatusLabelTag color={colors[textColor]} bg={colors[badgeColor]}>
      <Box display="flex">
        <Box pr="6px" display="inline">
          <Icon name="arrow-trend" colorName={textColor} size={12} />
        </Box>
        <span>{getText('Actively Hiring')}</span>
      </Box>
    </ListingStatusLabelTag>
  );
}
