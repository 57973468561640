import { log } from 'utils/logging';

import { ActionOppsIconName } from './ActionOppsIconName';
import { ActionOppsIconSrcByName } from './ActionOppsIconSrcByName';

type Props = {
  name: ActionOppsIconName;
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
};

export function ActionOppsIcon({
  name,
  width,
  height,
  maxWidth,
  maxHeight,
}: Props) {
  const ActionOppsIconSrc = ActionOppsIconSrcByName[name]();

  if (!ActionOppsIconSrc) {
    log.error(`Missing action-opps icon with name "${name}"`);
    return null;
  }

  return (
    <img
      width={width}
      height={height || 'auto'}
      src={ActionOppsIconSrc}
      alt={name || ''}
      style={{ maxWidth, maxHeight, objectFit: 'contain' }}
    />
  );
}
