import invariant from 'invariant';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiUserEnvironmentOrgMini } from 'api/userEnvironment/types/ApiUserEnvironmentOrgMini';
import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { HideListingContainer } from 'containers/HideListingContainer';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { LISTING_TYPE_URL_SEGMENT_MAP } from 'utils/constants/general/listingTypeUrlSegmentMap';

type Props = {
  hit: SearchAlgoliaHit;
  org: ApiUserEnvironmentOrgMini;
};

export function SearchHitOrgOwnerBannerButtons({ hit, org }: Props) {
  const navigate = useNavigate();

  invariant(hit.type !== 'IMPORTED', 'hit.type is "IMPORTED"');
  invariant(org, 'Missing org');

  const viewUrl = useMemo(() => {
    if (hit.type === 'ORG') return `/admin/${org.id}`;
    return `/admin/${org.id}/${LISTING_TYPE_URL_SEGMENT_MAP[hit.type]}/${
      hit.objectID
    }`;
  }, [hit.objectID, hit.type, org.id]);

  return (
    <Box display="flex" gridGap={2}>
      <Button
        type="link"
        href={`${viewUrl}/edit`}
        data-qa-id="edit-button"
        size="small"
        variant="secondary-white"
      >
        {getText('Edit')}
      </Button>

      {hit.type !== 'ORG' && org && (
        <>
          <HideListingContainer
            listingId={hit.objectID}
            listingType={hit.type}
            orgId={org.id}
            render={({ hideListing, submitting }) => (
              <Button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  hideListing().then(() => {
                    navigate(viewUrl, {
                      state: {
                        flashMessage: {
                          type: 'LISTING_HIDDEN',
                        },
                      },
                    });
                  });
                }}
                disabled={submitting}
                data-qa-id="hide-button"
                size="small"
                variant="secondary-white"
              >
                {
                  /* TRANSLATORS: hide listing, button text */
                  getText('Hide')
                }
              </Button>
            )}
          />

          <Button
            data={
              hit.type === 'VOLOP' && org && !org.canPostVolops
                ? { type: 'button', disabled: true }
                : { type: 'link', href: `${viewUrl}/repost` }
            }
            data-qa-id="repost-button"
            size="small"
            variant="secondary-white"
          >
            {getText('Repost')}
          </Button>
        </>
      )}
    </Box>
  );
}
