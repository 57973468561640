import { Fragment, ReactNode } from 'react';
import { sprintf } from 'sprintf-js';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { Box } from 'components/Box';
import { IdealistDayLabel } from 'components/IdealistDays/IdealistDayLabel';
import { BodyMicro } from 'components/Text/BodyMicro';
import { ListingStatusLabel } from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { ListingTypeLabel } from 'modules/listing/components/ListingTypeLabel/ListingTypeLabel';
import { SearchHitInfoBoxListingLabels } from 'modules/search/components/Hit/Info/ListingLabels/SearchHitInfoBoxListingLabels';
import {
  SearchHitInfoHeader,
  SearchHitInfoHeaderHeading,
  SearchHitInfoHeaderOrgName,
  SearchHitInfoHolder,
  SearchHitInfoPipe,
  SearchHitInfoStats,
  SearchHitInfoStatsRow,
} from 'modules/search/components/Hit/Info/SearchHitInfo.styled';
import { shorterDate } from 'utils/date';

type Props = {
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  endDateTime?: ApiISODateTimeString | null;
  location?: string;
  locationType?: ApiListingLocationType;
  listingStats: ReactNode[];
  name: string;
  orgName?: string | null;
  orgType?: string | null;
  showLabel?: boolean;
  startDateTime?: ApiISODateTimeString | null;
  timezone?: string | null;
  variant: 'search' | 'sidebar';
  isIdealistDay?: boolean;
};

export function SearchEventHitInfo({
  application,
  endDateTime,
  location,
  locationType,
  listingStats,
  name,
  orgName,
  orgType,
  showLabel,
  startDateTime,
  timezone,
  variant,
  isIdealistDay,
}: Props) {
  const type = 'EVENT';
  return (
    <SearchHitInfoHolder $variant={variant}>
      {showLabel && (
        <div style={{ marginBottom: 14 }}>
          <ListingTypeLabel listingType={type} />
        </div>
      )}

      {isIdealistDay && (
        <Box mb="12px">
          <IdealistDayLabel />
        </Box>
      )}

      <SearchHitInfoHeader $variant={variant}>
        <SearchHitInfoHeaderHeading>
          <div>
            <span data-qa-id="search-result-link">{name.slice(0, 120)}</span>
            {name.length > 120 && <>&hellip;</>}
          </div>
        </SearchHitInfoHeaderHeading>

        {(orgType === 'CORPORATION' || orgName) && (
          <SearchHitInfoHeaderOrgName $variant={variant}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {orgName}
            </Box>
          </SearchHitInfoHeaderOrgName>
        )}
      </SearchHitInfoHeader>

      <SearchHitInfoBoxListingLabels
        listingType={type}
        location={location}
        locationType={locationType}
        timezone={timezone}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        variant={variant}
      />

      <SearchHitInfoStatsRow>
        {(listingStats.length > 0 || application) && (
          <SearchHitInfoStats>
            {listingStats.map((info, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {index > 0 && <SearchHitInfoPipe />}
                <BodyMicro lineHeight={1}>{info}</BodyMicro>
              </Fragment>
            ))}

            {application && (
              <>
                <ListingStatusLabel
                  listingStatus={application.status}
                  asTag
                  forceSolid
                />

                <Box display="inline-block" ml={12}>
                  <BodyMicro>
                    {sprintf(getText('Applied %(date)s'), {
                      date: shorterDate(application.created),
                    })}
                  </BodyMicro>
                </Box>
              </>
            )}
          </SearchHitInfoStats>
        )}
      </SearchHitInfoStatsRow>
    </SearchHitInfoHolder>
  );
}
