import { sprintf } from 'sprintf-js';

import { ApiUserEnvironmentOrgMini } from 'api/userEnvironment/types/ApiUserEnvironmentOrgMini';
import { BodySmall } from 'components/Text/BodySmall';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { LISTING_TYPE_HUMAN_NAME_MAP } from 'utils/constants/general/listingTypeHumanNameMap';

import { SearchHitOwnerOrgBannerHolder } from './SearchHitOrgOwnerBanner.styled';
import { SearchHitOrgOwnerBannerButtons } from './SearchHitOrgOwnerBannerButtons';

type Props = {
  org: ApiUserEnvironmentOrgMini;
  hit: SearchAlgoliaHit;
};

export function SearchHitOwnerOrgBanner({ org, hit }: Props) {
  return (
    <SearchHitOwnerOrgBannerHolder>
      <BodySmall>
        {hit.type === 'ORG'
          ? getText('You are an administrator of this organization')
          : sprintf(
              getText('Your organization posted this %(listingTypeName)s'),
              {
                listingTypeName: LISTING_TYPE_HUMAN_NAME_MAP[hit.type],
              },
            )}
      </BodySmall>

      <SearchHitOrgOwnerBannerButtons org={org} hit={hit} />
    </SearchHitOwnerOrgBannerHolder>
  );
}
