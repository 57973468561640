import { Box } from 'components/Box';
import { Color, colors } from 'theme/theme';

import { Amount, AmountContainer } from './Meter.styled';

type Props = {
  total: number;
  current: number;
  color?: Color;
  duration?: number;
  delay?: number;
};

export function Meter({
  total,
  current,
  color = 'brandYellow',
  duration = 1,
  delay = 0.33,
}: Props) {
  return (
    <Box overflow="hidden" borderRadius="3px" bg={colors.selectionGrey}>
      <AmountContainer $duration={duration} $progress={current / total}>
        <Amount bg={color} height="6px" $delay={delay} $duration={duration} />
      </AmountContainer>
    </Box>
  );
}
