import { getStaticImageSrc } from 'utils/getImageSrc';

// We have to use functions because the config is not always set at
// this point, because it's global scope.
export const ActionOppsIconSrcByName = {
  nycparks: () =>
    getStaticImageSrc('icons/action-opps/action-opps-nycparks.svg'),
  'global-giving': () =>
    getStaticImageSrc('icons/action-opps/action-opps-global-giving.svg'),
  volunteermatch: () =>
    getStaticImageSrc('icons/action-opps/action-opps-volunteermatch.svg'),
  'move-on': () =>
    getStaticImageSrc('icons/action-opps/action-opps-move-on.svg'),
  'points-of-light': () =>
    getStaticImageSrc('icons/action-opps/action-opps-points-of-light.svg'),
  aarp: () => getStaticImageSrc('icons/action-opps/action-opps-aarp.svg'),
  'do-something': () =>
    getStaticImageSrc('icons/action-opps/action-opps-do-something.svg'),
  newyorkcares: () =>
    getStaticImageSrc('icons/action-opps/action-opps-newyorkcares.svg'),
  volunteergov: () =>
    getStaticImageSrc('icons/action-opps/action-opps-volunteergov.svg'),
  citizenconnect: () =>
    getStaticImageSrc('icons/action-opps/action-opps-citizen-connect.svg'),
  'donors-choose': () =>
    getStaticImageSrc('icons/action-opps/action-opps-donors-choose.svg'),
  grapevine: () =>
    getStaticImageSrc('icons/action-opps/action-opps-grapevine.jpg'),
  transforma: () =>
    getStaticImageSrc('icons/action-opps/action-opps-transforma.jpg'),
  golden: () => getStaticImageSrc('icons/action-opps/action-opps-golden.png'),
} as const;
