import styled from 'styled-components';

import { colors } from 'theme/theme';

export const SearchHitSaveButton = styled.button<{
  $saved: boolean;
}>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.2s ease-out;
  padding: 0 8px;
  border-radius: 32px;
  background-color: ${colors.calloutGrey};
  color: ${colors.lightContentGrey};

  span {
    display: none;
    opacity: 0;
    padding-left: 8px;
    line-height: 1px;
    font-size: 14px;
  }

  &:hover {
    background: ${colors.softBlue};
    color: ${colors.brandBlue};
  }

  ${(props) =>
    props.$saved &&
    `
      background: ${colors.lightSage};
      color: ${colors.brandGreen};
    `}
`;
